import { useTranslation } from "react-i18next";
import Popup from "../../helpers/Popup";
import "./index.scss";

const TermsPopup = () => {
  const { t } = useTranslation();

  return (
    <Popup className="terms-popup" title="Terms & Conditions">
      <div style={{ textAlign: "left", padding: ".5em" }}>
        
    
<p dir="ltr">
    <strong>INTRODUCTION:</strong>
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Allied Blenders and Distillers Limited a company incorporated and
            registered under the provisions of the Companies Act, 1956 and now
            deemed to be registered under the provisions of the Companies Act,
            2013, having its registered office at 394/C, Ground Floor, Lamington
            Chambers, Lamington Road, Mumbai - 400 004, Maharashtra, India for
            itself and its subsidiaries, affiliates and representatives
            hereinafter referred to as the <strong>
              “Promoter / ABDL”,
              </strong>
        </p>
    </li>
</ol>
<ol start={2}>
    <li dir="ltr">
        <p dir="ltr">
            Premier Sales Promotions Private Limited a company incorporated and
            registered under the provisions of the Companies Act, 1956 and now
            deemed to be registered under the provisions of the Companies Act,
            2013, having its registered office at Mitra Towers, Kasturba Road,
            Bangalore 560001, India for itself and its subsidiaries, affiliates
            and representatives hereinafter referred to as the “<strong>Payment
            Processing Partner / PSPPL</strong>”. PSPPL shall create the QR codes which
            after scanning voucher holder / purchaser / end user / every person
            / consumer lands on a webpage/microsite, where he shall fill his
            details and receives cashback. PSPPL shall generate the cashback
            &amp; send it to the voucher holder / purchaser / end user / every
            person / consumer. PSPPL shall generate a unique referral code &amp;
            a link which the voucher holder / purchaser / end user / every
            person / consumer can share with his friends through whats app / SMS
            etc. This referral code can be used by secondary consumer (referred
            consumer).
        </p>
    </li>
</ol>

<ol start={3}>
    <li dir="ltr">
        <p dir="ltr">
            This Voucher entitles voucher holder / purchaser / end user / every
            person / consumer hereinafter referred to as the “Participant” to an
            assured cashback of Rs. 10/- on purchase of select SKU’s of Allied
            Blenders and Distillers Private Limited products in selected cities
            of India and the offer period of the same shall be from 21st June
            2024 to 4th August, 2024.
        </p>
    </li>
</ol>

<ol start={4}>
    <li dir="ltr">
        <p dir="ltr">
            With each voucher, the Participant will be eligible for maximum Rs.
            10/- cashback.
        </p>
    </li>
</ol>

<p dir="ltr">
    <strong>Terms and Conditions for receiving Cashback</strong>
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            This offer entitles the Participant to receive cashback in their
            Bank Account or in their UPI wallet directly. The Participant will
            be responsible for their choice of the cashback transfer method. The
            denomination of the cashback will be mentioned on the
            voucher/scratch card.
        </p>
    </li>
</ol>
<ol start={2}>
    <li dir="ltr">
        <p dir="ltr">
            Any consumer purchasing promotional product shall be eligible to
            receive a Voucher inside the pack. The Participant shall receive a
            cashback of an amount equivalent to the value as mentioned on the
            Voucher (“Cashback/Voucher Amount”).
        </p>
    </li>
</ol>
<ol start={3}>
    <li dir="ltr">
        <p dir="ltr">
            By redeeming the Offer provided on the same, a participant agrees to
            be bound by the terms and conditions as stated herein(“Conditions”).
        </p>
    </li>
</ol>
<ol start={4}>
    <li dir="ltr">
        <p dir="ltr">
            These Conditions apply to every Participant redeeming the offer
            given by PSPPL, no claim shall lie for any redemption requests made
            after the expiry of the Offer Period.
        </p>
    </li>
</ol>
<ol start={5}>
    <li dir="ltr">
        <p dir="ltr">
            The Participant will be responsible for entering details such as UPI
            ID, Bank Account Number etc. PSPPL will not be responsible for any
            incorrect entries or details on the promotion website. changes to
            any details shall notl be allowed once Participant have submitted
            the details and same has been accepted by the system.
        </p>
    </li>
</ol>
<ol start={6}>
    <li dir="ltr">
        <p dir="ltr">
            The Payment Processing Partner holds the right to refuse
            transactions deemed fraudulent as per their policies. In the event
            of any fraud detection, Payment Processing Partner reserves the
            right to modify/change, discontinue, terminate, withdraw the Offer
            at any time during the Offer Period.
        </p>
    </li>
</ol>
<ol start={7}>
    <li dir="ltr">
        <p dir="ltr">
            In no event shall PSPPL or its partner agency be held liable for any
            issues relating to the redemption of the voucher including
            redemption request not being honoured by Payment Processing Partner.
        </p>
    </li>
</ol>
<ol start={8}>
    <li dir="ltr">
        <p dir="ltr">
            In no event shall the maximum liability under this Terms &amp;
            Conditions shall exceed the value of the voucher i.e. Maximum of Rs.
            10/- (Rupees Ten Only).
        </p>
    </li>
</ol>
<ol start={9}>
    <li dir="ltr">
        <p dir="ltr">
            Any disputes or differences in connection with the offer shall be
            governed by laws of India and shall be subject to the exclusive
            jurisdiction of the Courts at Mumbai.
        </p>
    </li>
</ol>
<ol start={10}>
    <li dir="ltr">
        <p dir="ltr">
            The Payment Processing Partner reserve the right to restrict
            cashback for any account with suspicious behavior or invalid
            details/credentials. The cashback processing partner has the right
            to ask for documents to prove the Participant’s/user’s identity.
        </p>
    </li>
</ol>
<ol start={11}>
    <li dir="ltr">
        <p dir="ltr">
            This offer is subject to promotional availability and government
            regulations.
        </p>
    </li>
</ol>
<p dir="ltr">
    <strong>General Terms &amp; Conditions</strong>
</p>

<ol>
    <li dir="ltr">
        <p dir="ltr">
            This free-to-enter offer is open to all Indian residents of age 21
            years and above and must legally adhere to this Terms &amp;
            Conditions.  Each Voucher entitles Participant for  an assured
            cashback of Maximum Rs. 10/-  (Rupees Ten Only) on purchase of
            selected SKU’s of products manufactured and marketed by Allied
            Blenders and Distillers  Limited in selected cites of India only.
            Each Participant to certify that they have read and reviewed this
            Terms &amp; Conditions and that they agree to comply with its terms.
            If Participant do not want to be bound by this Terms &amp;
            Conditions, they are advised to stop utilizing the voucher
            accordingly.
        </p>
    </li>
</ol>
<ol start={2}>
    <li dir="ltr">
        <p dir="ltr">
            This offer is not available to employees of Promoter its
            subsidiaries and their families members and PSPPL, its clients or
            agencies and their immediate families.
        </p>
    </li>
</ol>
<ol start={3}>
    <li dir="ltr">
        <p dir="ltr">
            The usage of this Voucher is incumbent upon the terms &amp;
            conditions to be followed by the Participant. Such conditions need
            to be followed by the Participant.
        </p>
    </li>
</ol>
<ol start={4}>
    <li dir="ltr">
        <p dir="ltr">
            The "How to Use/Redeem" instructions form a part of these Terms
            &amp; Conditions.
        </p>
    </li>
</ol>
<ol start={5}>
    <li dir="ltr">
        <p dir="ltr">
            The Promotion &amp; contest shall run on the select SKUs only.
        </p>
    </li>
</ol>
<ol start={6}>
    <li dir="ltr">
        <p dir="ltr">
            Pursuant to the terms of the offer, Participants are entitled to
            refer others using their unique referral code. In the event that a
            referred participant avails of the promotion by utilizing the
            referral code, the referrer shall be eligible to receive an
            additional cashback of Rs. 10/- for each successful referral. It is
            hereby clarified that such referral cashback is subject to a maximum
            limit of 8 (Eight) occurrences per user, per mobile number, per
            email address, and per account.
        </p>
    </li>
</ol>
<ol start={7}>
    <li dir="ltr">
        <p dir="ltr">
            Any costs associated with entering the promotion, including
            accessing the promotional website, are the responsibility of each
            Participant. Also, regular call and message rates will apply as per
            the consumer telecom operator in their respective circles.
        </p>
    </li>
</ol>
<ol start={8}>
    <li dir="ltr">
        <p dir="ltr">
            Only one voucher code per booking will be entertained. Multiple
            vouchers cannot be clubbed and booked together.
        </p>
    </li>
</ol>
<ol start={9}>
    <li dir="ltr">
        <p dir="ltr">
            A maximum of 2 (Two) vouchers can be used per person, per mobile
            number, per email id, per account  per day and up to 12 (Twelve)
            vouchers can be used during the promotion period.
        </p>
    </li>
</ol>
<ol start={10}>
    <li dir="ltr">
        <p dir="ltr">
            The usage of this Voucher is incumbent upon the terms &amp;
            conditions to be followed by the Participant and such conditions
            needs to be followed by the Participant.
        </p>
    </li>
</ol>
<ol start={11}>
    <li dir="ltr">
        <p dir="ltr">
            PSPPL is acting as an executing agency for this promotional offer
            and holds no legal and financial liability on unclaimed offers,
            incentives, vouchers, etc beyond the applicable period of the
            promotional offer.
        </p>
    </li>
</ol>
<ol start={12}>
    <li dir="ltr">
        <p dir="ltr">
            This offer is subject to promotional availability and government
            regulations.
        </p>
    </li>
</ol>
<ol start={13}>
    <li dir="ltr">
        <p dir="ltr">
            Under this promotion, the Vouchers are being offered directly by
            PSPPL. Promoter shall not be responsible either directly or
            indirectly with respect to any manner pertaining to this Voucher and
            the prize offered under  this Voucher.
        </p>
    </li>
</ol>
<ol start={14}>
    <li dir="ltr">
        <p dir="ltr">
            PSPPL and Promoter reserves the right to change the terms and
            conditions of this scheme, including extending, withdrawing, or
            discontinuing the same without any notice to the Participant’s,
            without assigning any reason, at their sole discretion and without
            any liability.
        </p>
    </li>
</ol>
<ol start={15}>
    <li dir="ltr">
        <p dir="ltr">
            PSPPL shall not be liable in any manner for any cost or expense
            incurred for the purpose of redemption of the Voucher.
        </p>
    </li>
</ol>
<ol start={16}>
    <li dir="ltr">
        <p dir="ltr">
            If this promotion is interfered with in any way or is not capable of
            being conducted as reasonably anticipated due to any reason beyond
            the reasonable control of the Promoter, including but not limited to
            technical difficulties, unauthorized intervention or fraud, the
            Promoter reserves the right, in its sole discretion, to the fullest
            extent permitted by law (a) to invalidate any Participant; or (b)
            subject to any written directions from a regulatory authority, to
            modify, suspend, terminate or cancel the promotion, as appropriate.
        </p>
    </li>
</ol>
<ol start={17}>
    <li dir="ltr">
        <p dir="ltr">
            PSPPL shall be not responsible for any problems or technical
            malfunction of any telephone or network or lines, servers or
            providers, computer equipment, software, technical problems or
            traffic congestion on a mobile network, or any combination thereof,
            or any other technical failures including any damage to entrant's or
            any other person's mobile handset or computer related to, or
            resulting from, participation in this promotion or the downloading
            of any materials related to this promotion.
        </p>
    </li>
</ol>
<ol start={18}>
    <li dir="ltr">
        <p dir="ltr">
            If for any reason this promotion is not capable of running as
            planned because of infection by computer virus, bugs, tampering,
            unauthorized intervention, technical failures or any other causes
            beyond the reasonable control of the PSPPL which corrupt or affect
            the administration, security, fairness, integrity or proper conduct
            of this promotion, PSPPL reserves the right in its sole discretion
            to cancel, terminate, modify or suspend the promotion, subject to
            any written directions made under applicable State or Territory
            legislation.
        </p>
    </li>
</ol>
<ol start={19}>
    <li dir="ltr">
        <p dir="ltr">
            Caution: Any attempt to deliberately damage the Promoter’s
            promotional website or the information on the website, to cause
            malicious damage or interference with the normal functioning of the
            website, or to otherwise undermine the legitimate operation of this
            promotion may be a violation of criminal and civil laws and should
            such an attempt be made, whether successful or not, the Promoter
            reserves the right to seek damages to the fullest extent permitted
            by law. If the Promoter suffers loss or incurs any costs in
            connection with any breach of these Terms or any other legal
            obligation by an entrant, the entrant agrees to indemnify the
            promoter for those losses, damages, and costs.
        </p>
    </li>
</ol>
<ol start={20}>
    <li dir="ltr">
        <p dir="ltr">
            This voucher has no monetary value, is non-transferable and cannot
            be used in conjunction with any other promotional offer. Voucher
            shall not be exchanged or substituted for cash or any other products
            / services.
        </p>
    </li>
</ol>
<ol start={21}>
    <li dir="ltr">
        <p dir="ltr">
            PSPPL or Promoter will have no legal and financial liability on
            unclaimed offers, incentives, vouchers, etc. beyond the applicable
            period of the promotional offer.
        </p>
    </li>
</ol>
<ol start={22}>
    <li dir="ltr">
        <p dir="ltr">
            PSPPL, its clients, agents, and distributors accept no
            responsibility, financial or otherwise for the misuse of Promoter’s
            Signature on  the PSPPL’s vouchers and are not liable for any
            personal loss or injury and cannot be held responsible for any
            disagreement concerning quality / availability of featured offers.
        </p>
    </li>
</ol>
<ol start={23}>
    <li dir="ltr">
        <p dir="ltr">
            Promoter does not endorse the quality of any of the products or
            services being offered by the PSPPL or by the cashback partners. Any
            complaints or queries pertaining to the same would have to be taken
            up directly with the PSPPL marketing team for such a product or
            service.
        </p>
    </li>
</ol>
<ol start={24}>
    <li dir="ltr">
        <p dir="ltr">
            PSPPL, its clients, agents, and distributors cannot replace any
            lost, stolen or damaged vouchers and reserve the right to withdraw
            or amend any details and/or prices without notice.
        </p>
    </li>
</ol>
<ol start={25}>
    <li dir="ltr">
        <p dir="ltr">
            These Terms &amp; Conditions are subject to Indian Law and the
            exclusive jurisdiction of the Courts in Mumbai.
        </p>
    </li>
</ol>
<ol start={26}>
    <li dir="ltr">
        <p dir="ltr">
            Offer Period for this Promotion  shall be from 21st June 2024 to 4th
            August, 2024. Post the end of the activation PSPPL shall share with
            ABDL total number of participants received cashback, and raise their
            invoice accordingly on ABDL.
        </p>
    </li>
</ol>
<ol start={27}>
    <li dir="ltr">
        <p dir="ltr">
            By entering / participating in the contest all Participants accept
            and agree to the terms and conditions stated herein. Participant
            agrees to indemnify Promoter and PSPPL their affiliates and hold
            Promoter and PSPPL harmless against legal claims and demands that
            may arise from Participants use or misuse of the Voucher. The
            Participants agree and accept that no claim, actions, proceedings,
            costs, damages, expenses, penalties, demands or liabilities,
            howsoever arising shall stand against Promoter, its affiliates /
            subsidiaries and their respective employees, officers and directors
            in any manner directly or indirectly arising from or attributable to
            this contest.
        </p>
    </li>
</ol>
<ol start={28}>
    <li dir="ltr">
        <p dir="ltr">
            All offers are supplied by PSPPL, Mitra Towers, Kasturba Road,
            Bangalore 560001, India. E-mail: feedback@bigcity.in and all queries
            should be sent to the said address / email only.
        </p>
    </li>
</ol>

      </div>
    </Popup>
  );
};

export default TermsPopup;
