import { PropsWithChildren, useState } from "react";
import headerlogo from "../assets/images/header-logo.png";
import headersublogo from "../assets/images/Piyo.png";
import desktoplogobg from "../assets/images/desktop-logo-bg.png";
import desktoplogo from "../assets/images/Logo.png";

import Logo from "../assets/images/Logo.png";
import MenuIcon from "../assets/images/menu-icon.svg";
import footer from "../assets/images/bottom.png";
import desktopfooter from "../assets/images/desktop-bottom.png";

import "./MainLayout.scss";
import SideMenu from "../components/SideMenu/SideMenu";
import { MODAL_TYPES, useGlobalModalContext } from "../helpers/GlobalModal";
import { useTranslation } from "react-i18next";
import { LANGUAGE, LANGUAGES, ROUTES } from "../lib/consts";
import { useNavigate } from "react-router-dom";

const MainLayout = ({
  children,
  className,
}: { className: string } & PropsWithChildren) => {
  const [showSideMenu, setShowSideMenu] = useState(false);
  const { showModal } = useGlobalModalContext();
  const navigate = useNavigate();
  // const { t } = useTranslation();
  const { t, i18n } = useTranslation();
  // console.log(i18n);
  return (
    <div className={"main-layout " + className}>
      <SideMenu open={showSideMenu} onClose={() => setShowSideMenu(false)} />
      <header>
        {/*<select
          className="language-selection header-lang"
          onChange={(e) => {
            i18n.changeLanguage(e.target.value);
          }}
        >
          {Object.keys(LANGUAGES).map((key) => {
            if (i18n.language === key) {
              return (
                <option key={key} value={key} selected>
                  {LANGUAGES[key as LANGUAGE]}
                </option>
              );
            }

            return (
              <option key={key} value={key}>
                {LANGUAGES[key as LANGUAGE]}
              </option>
            );
          })}
        </select>*/}

        <img
          src={MenuIcon}
          onClick={() => setShowSideMenu(true)}
          alt="menu"
          className="menu-icon"
        />
        {
          <img
            src={headerlogo}
            alt="logo"
            className="logo"
            onClick={() => navigate(ROUTES.REGISTER)}
          />
        }
        <img src={headersublogo} alt="sub-log" className="sub-logo" />
      </header>
      <div className="content">{children}</div>
      <img src={footer} className="mobile-footer" alt="footer" />
    </div>
  );
};

export default MainLayout;
