import {useTranslation} from "react-i18next";
import Popup from "../../helpers/Popup";

const HowTORedeemPopup = () => {
    const { t } = useTranslation();

    return (
        <Popup className="howtoredeem-us-popup" title="HOW TO REDEEM">
            <p dir="ltr" id="docs-internal-guid-db7a02d5-7fff-51a4-d75f-91dc92ef878e">
    Steps to Redeem:
</p>
<ol>
    <li dir="ltr">
        <p dir="ltr">
            Enter Unique Code, Name, Mobile number, referral code (optional) and
            accept Terms and Conditions and click on Submit.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Enter the OTP received on your registered mobile number.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Choose the preferred mode of cashback, enter the details and submit.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            You will receive your cashback in your selected mode within 24-48
            business hours.
        </p>
    </li>
    <li dir="ltr">
        <p dir="ltr">
            Get a referral code along with the link and share it with your
            friends to receive additional cashback for each successful referral.
        </p>
    </li>
</ol>
<br/>

        </Popup>
    );
};

export default HowTORedeemPopup ;