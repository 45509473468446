import "./Congratulation.scss";
import MainLayout from "../../layouts/MainLayout";
import { ROUTES } from "../../lib/consts";
import { useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import API from "../../api";
import { useDispatch, useSelector } from "react-redux";
// import {
//   isFirstGame,
//   setIndividualScore,
// } from "../../store/actions/authAction";
import { useAuthentication } from "../../hooks/useAuthentication";
import { RootState } from "../../store/store";
import { GA_EVENTS, gtagTrackEvent } from "../../lib/utils";
import congrats from "../../assets/images/congrats.png";
import { ReactComponent as Share } from "../../assets/images/share.svg";
import { WhatsappShareButton } from "next-share";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as CopyIcon } from "../../assets/images/copy.svg"; // Import your copy icon here

export const Congratulation = () => {
  const { isLoggedIn } = useAuthentication();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<number | null>(null);
  const [isCorrect, setIsCorrect] = useState<boolean | any>(null);
  const [optionClicked, setOptionClicked] = useState<boolean>(false);
  const [referralCode, setReferralCode] = useState<string>("");
  //   const GameKey = useSelector((state: RootState) => state.authReducer.gameKey);
  const Url: string = "https://www.piyojeetojitao.com/";
  const [copied, setCopied] = useState<boolean>(false);

  useEffect(() => {
    API.getReferral().then((response) => {
      if (response.referral) {
        setReferralCode(response.referral);
        gtagTrackEvent(GA_EVENTS.Thank_You);
      } else {
        // Handle unexpected response structure or set a default message
        setReferralCode("Referral Code");
      }
    });
  }, []);
  //
  const handleOptionClick = async (option: number) => {
    // if (!optionClicked) {
    // setOptionClicked(true);
    setSelectedOption(option);
  };
  const handleSubmit = () => {
    {
      /*navigate(ROUTES.HOME);*/
    }
  };



  const handleCopy = () => {
    setCopied(true);
    gtagTrackEvent(GA_EVENTS.Copy_Referral_Code); 
  };

  const handleShare = () => {
    gtagTrackEvent(GA_EVENTS.Share_Referral_Code); 
  };

  return (
    <MainLayout className="register-page">
      <div className="congrats-form">
        <div className="congratulation-container">
          {/*<img src={Text} alt="logo-top" />*/}
          <p className="congratulation-title">Congratulations!</p>
          <p className="congratulation-text">
            Thank you for your details. You <br />
            will receive your assured Cashback <br />
            within 24-48 business hours.
          </p>
          <div className="referral-input">
            <p className="referal-code">{referralCode}</p>

            <CopyToClipboard text={referralCode} onCopy={handleCopy}>
              <button className="copy-button">
                <CopyIcon className="copy-icon" />
                {copied ? "Copied!" : "Copy"}
              </button>
            </CopyToClipboard>
          </div>

          <p className="congratulation-text">
            In case of any queries, please <br />
            write to us at <br />
            feedback@bigcity.in
          </p>
        </div>
      </div>
      <WhatsappShareButton
        url={`${Url}?referral=${referralCode}`}
        // url={Url}
        title={`I have participated in PIYO, JEETO, JITAO contest of my favorite ICONiQ WHITE & won Rs.10 cashback. Now its your turn to buy an ICONiQ WHITE and use my referral code : ${referralCode} to win cashback. Click on this link to claim it.`}
        separator=": "
        onClick={handleShare}
        className="share-button"
        style={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          flexDirection: "row-reverse",
          color: "#fff",
          fontSize: "18px",
          fontWeight: "600",
          background: "#000",
          padding: "12px 20px",
          boxShadow:"0px 0px 48px 0px #F2BA80",
          borderImageSource: `linear-gradient(
            90deg,
            rgba(124, 68, 45, 1),
            rgba(247, 173, 119, 1),
            rgba(242, 186, 128, 1),
            rgba(124, 68, 45, 1),
            rgba(247, 173, 119, 1),
            rgba(242, 186, 128, 1),
            rgba(247, 173, 119, 1),
            rgba(124, 68, 45, 1)
          )`,
          borderImageSlice: 1,
          borderWidth: "4px",
          borderStyle: "solid",
          fontFamily: "FuturuStd",
        }}
      >
        <Share className="share-icon" />
        <span className="share-text">Share to a Friend</span>
      </WhatsappShareButton>
    </MainLayout>
  );
};
